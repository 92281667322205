<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '740px' }"
    header="Fomulário de Situação"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field">
      <label for="description">Descrição</label>
      <InputText
        id="description"
        v-model="v$.situation.description.$model"
        maxlength="255"
        placeholder="Digite a descrição do tipo de vinculo"
        :class="{ 'p-invalid': submitted && v$.situation.description.$invalid }"
      />
      <small
        class="p-error"
        v-if="submitted && v$.situation.description.$invalid"
        >Tipo de Vínculo é obrigatório.</small
      >
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.situation.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Situation from "../../../models/situation";

//Services
import SituationService from "../../../service/situation/situation_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["situationSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      situation: new Situation(),
      submitted: false,
      situationService: new SituationService(),
    };
  },
  created() {},
  validations() {
    return {
      situation: new Situation().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.situation.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.situation.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.situation.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.situationService
        .create(this.situation)
        .then((data) => {
          if (data.status === 201) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro cadastrado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.situationService
        .update(this.situation)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro alterado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.situation = new Situation();
      this.submitted = false;
      this.$emit("findAll");
      this.visibleDialog = false;
    },
    getData() {
      this.situation = this.situationSelected;
    },
  },
};
</script>
<style scoped></style>
